import React from 'react'
import styled from 'styled-components'

import Button from './Button'
import { COLORS } from './constants'

const title = 'Start browsing safely!'

const Section = styled.section`
  align-items: center;
  background-color: #F4F4F4;
  display: flex;
  flex-direction: column;
  padding: 290px 0 115px;
`

const Title = styled.h2`
  font-size: 48px;
  margin: 0 0 45px; 
`

const StartBrowsing = () => (
  <Section>
    <Title>{title}</Title>
    <Button color={COLORS.PRIMARY} />
  </Section>
)

export default StartBrowsing
