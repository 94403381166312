import React from 'react'
import styled from 'styled-components'

import Choose from '../images/choose.png'
import Activate from '../images/activate.png'
import ExplanationCard from './ExplanationCard'

const cards = [
  {
    title: 'Choose',
    text: 'From a ready-made DNS list',
    img: Choose,
    imgAlt: 'Application screen shot with OpenDNS Family selected from the list',
  },
  {
    title: 'Activate',
    text: 'Your DNS with one click',
    img: Activate,
    imgAlt: 'Application screen shot with highlighted button Turn On DNS',
    imgPositionLeft: true
  }
]

const Section = styled.section`
  aling-items: center;
  background-color: #F4F4F4;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`

const Title = styled.h2`
  font-size: 48px;
  margin: 140px auto 95px;
`

const title = 'Protect your family'

const ProtectFamily = () => (
  <Section>
    <Title>{title}</Title>
    {cards.map(({ title, text, img, imgAlt, imgPositionLeft}, index) => (
      <ExplanationCard key={index} title={title} text={text} img={img} imgAlt={imgAlt} imgPositionLeft={imgPositionLeft} />
    ))}
  </Section>
)

export default ProtectFamily
