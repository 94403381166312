import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const title = 'Protect'
const text = 'Yourself and your family'
const altText = ''

const Wrapper = styled.section`
  position: relative;
`

const Section = styled.section`
  align-items: center;
  display: flex;
  height: 680px;
  width: 100%;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
`

const Title = styled.h2`
  color: white;
  font-size: 60px;
  margin: 85px 0 15px;
`

const Text = styled.p`
  color: white;
  font-size: 32px;  
  margin: 0;
`

const Picture = styled.picture`
  bottom: -275px;
  position: absolute;
`

const Protect = () => {
  const data = useStaticQuery(graphql`
    query ProtectQuery {
      sectionBackground: file(relativePath: {eq: "blue@1x.png"}) {
        childImageSharp{
          fixed(width: 1280, height: 680) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      protectImage: file(relativePath: {eq: "protect.png"}) {
        childImageSharp{
          fixed(width: 588, height: 666) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Img fixed={data.sectionBackground.childImageSharp.fixed} alt={altText} />
      <Section>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <Picture>
          <Img fixed={data.protectImage.childImageSharp.fixed} alt={altText} />
        </Picture>
      </Section>
    </Wrapper>
  )
}

export default Protect
