import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Button from './Button'
import Logo from './Logo'
import { COLORS } from './constants'

const title = 'Make your browsing family friendly'
const subtitle = 'Protect your family from unwanted websites'

const Wrapper = styled.section`
  position: relative;
  margin-bottom: 390px;
`

const Section = styled.div`
  color: #000000;
  display: flex;
  flex-direction: column;
  height: 700px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 100px;
`

const Title = styled.h1`
  font-size: 48px;
  font-weight: bold;
  max-width: 520px;
  margin: 0 0 25px;
`

const Subtitle = styled.p`
  font-size: 20px;
  line-height: 24px;
  max-width: 500px;
  margin: 0 0 35px;
`

const Picture = styled.picture`
  position: absolute;
  left: 0;
  bottom: -345px;
  width: 100%;
  img {
    width: 100%;
  }
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      hero: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1280, height: 700) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      headerImage: file(relativePath: { eq: "header-image.png" }) {
        childImageSharp {
          fixed(quality: 90, width: 1280, height: 630 ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Img backgroundColor="#FFE227" fixed={data.hero.childImageSharp.fixed} alt="smiling child with skateboard" />
      <Section>
        <Logo />
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Button color={COLORS.PRIMARY} />
        <Picture>
          <Img fixed={data.headerImage.childImageSharp.fixed} alt="application login screen over Apple iPad" />
        </Picture>
      </Section>
    </Wrapper>
  )
}

export default Header
