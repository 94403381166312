import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Section = styled.section`
  display: flex;
  width: 100%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${props => props.imgPositionLeft ? '7.5%' : '20%'}
  width: 100%;
`

const Title = styled.h3`
  color: #FCA875;
  font-size: 60px;
  margin: 0 0 15px;
`

const Text = styled.p`
  font-size: 32px;
  line-height: 39px;  
  margin: 0;
  max-width: 60%;
`

const Picture = styled.picture`
  width: 100%;
  order: ${props => props.imgPositionLeft ? '-1' : '0'};
`

const ExplanationCard = ({ title, text, img, imgAlt, imgPositionLeft }) => (
  <Section>
    <TextContainer imgPositionLeft={imgPositionLeft}>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </TextContainer>
    <Picture imgPositionLeft={imgPositionLeft}>
      <img src={img} alt={imgAlt} />
    </Picture>
  </Section>
)

ExplanationCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgPositionLeft: PropTypes.bool
}

export default ExplanationCard
