import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { COLORS } from '../components/constants'
import icon from '../images/win-logo.svg'

const setColor = ({ theme = COLORS.PRIMARY }) => [
  {
    [COLORS.PRIMARY]: css`
      background-color: #057BD9;
      `,
    [COLORS.DARK]: css`
        background: black;
      `
  }[theme]
]

const StyledButton = styled.button`
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  user-select: none;
  width: 235px;
  z-index: 1;
  ${setColor}
`

const Icon = styled.picture`
  margin: 18px 20px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Span = styled.span`
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
`

const Button = ({ color }) => {
  const handleClick = () => window.open('https://update.familyfriendlydns.com/ffdns_setup.exe', 'noopener noreferrer')
  return (
    <StyledButton theme={color} onClick={handleClick}>
      <Icon>
        <img src={icon} alt={'Microsoft Windows logo'} />
      </Icon>
      <TextContainer>
        <Span>Download</Span> for Windows
    </TextContainer>
    </StyledButton>
  )
}

Button.propTypes = {
  color: PropTypes.string
}

Button.defaultProps = {
  color: COLORS.PRIMARY
}

export default Button
