import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Header from '../components/Header'
import WhyUs from '../components/WhyUs'
import ProtectFamily from '../components/ProtectFamily'
import Protect from '../components/Protect'
import StartBrowsing from '../components/StartBrowsing'
import Footer from '../features/Footer'

const IndexPage = ({ data }) => (
  <Layout>
    <Seo
      title={data.site.siteMetadata.title}
      description={data.site.siteMetadata.description}
    />
    <Header />
    <WhyUs />
    <ProtectFamily />
    <Protect />
    <StartBrowsing />
    <Footer />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
