import React from 'react'
import ProsCard from './ProsCard'
import styled from 'styled-components'

import Safe from '../images/Safe.svg'
import Skull from '../images/Skull.svg'
import Children from '../images/Children.svg'

const cards = [
  {
    text: '<b>Protect</b> your <b>children</b> from inappropriate websites.',
    img: Children,
    imgAlt: 'Winking emoji'
  },
  {
    text: '<b>Feel safe</b> while browsing the net.',
    img: Safe,
    imgAlt: 'Shield with check mark in the middle'
  },
  {
    text: '<b>Filter</b> out threat-filled sites.',
    img: Skull,
    imgAlt: 'Skull'
  },
]

const title = 'Why Family Friendly DNS?'

const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`

const Title = styled.h2`
  font-size: 42px;
  font-weight: bold;
  margin: 0 0 70px;
  max-width: 400px;
  text-align: center;
`

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`

const WhyUs = () => (
  <Section>
    <Title>{title}</Title>
    <CardsContainer>
      {
        cards.map(({ text, img, imgAlt}, index) => (
          <ProsCard key={index} imgAlt={imgAlt} img={img} text={text}/>
        ))
      }
    </CardsContainer>
  </Section>
)

export default WhyUs
